import React, { Component } from "react"
import { Link } from "gatsby"
import Slider from "react-slick";
import { StaticImage } from 'gatsby-plugin-image'

export default class TopSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 4000,
      cssEase: 'ease-out',
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
          }
        }
      ]
    }

    return (
      <div>
          <Slider {...settings} className="top-slider">
            <div className="top-slider__item">
              <Link to="/feature/01/">
                <StaticImage
                  src="../assets/images/slider/gandhi-thumb.jpg"
                  alt="ガンディーの名言"
                />
              </Link>
            </div>

            <div className="top-slider__item">
              <Link to="/feature/02/">
                <StaticImage
                  src="../assets/images/slider/artist01.jpg"
                  alt="アーティスト"
                />
              </Link>
            </div>

            <div className="top-slider__item">
              <Link to="/feature/03/">
                <StaticImage
                  src="../assets/images/slider/senior01.jpg"
                  alt="引退"
                />
              </Link>
            </div>

            <div className="top-slider__item">
              <Link to="/feature/04/">
                <StaticImage
                  src="../assets/images/slider/ochikonda01.jpg"
                  alt="成功哲学"
                />
              </Link>
            </div>

            <div className="top-slider__item">
              <Link to="/feature/05/">
                <StaticImage
                  src="../assets/images/slider/jibun01.jpg"
                  alt="成功哲学"
                />
              </Link>
            </div>

          </Slider>
      </div>
    );
  }
}
