import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

const SearchResult = props => {
	const tempData = useStaticQuery(graphql`
			query SearchData {
				postForSearch: allContentfulBlogPost( sort: {fields: createdAt, order: DESC}) {
          edges {
            node {
              title
              slug
              createdAt(formatString: "YYYY-MM-DD")
              category {
                slug
								name
              }
            }
          }
				}
		}
	`)

	const allPosts = tempData.postForSearch.edges
	const emptyQuery = ""
	const [state, setState] = useState({
    filteredData: [],
    query: emptyQuery,
	})
	const handleInputChange = event => {
    console.log(event.target.value)
    const query = event.target.value
    const posts = tempData.postForSearch.edges || []

    const filteredData = posts.filter(post => {
      const title = post.node.title
      return (
        title.toLowerCase().includes(query.toLowerCase())
      )
    })
    setState({
      query,
      filteredData,
    })
	}
	const { filteredData, query } = state
	const hasSearchResults = filteredData && query !== emptyQuery
	const result = hasSearchResults ? filteredData : allPosts

	return (
		<div>
			<div className="m-result-inner">
				<input
					type="text"
					aria-label="Search"
					placeholder="キーワードを入力して名言を探す"
					onChange={handleInputChange}
					className="m-result__text"
				/>
				<div className="m-result__res">
					{query !== "" ?
						query + " の検索結果: " + result.length + "件"
						: result.length + "語の名言があります"
					}
				</div>
				<ul className="m-result__search">
					{result && result.map(({ node: post }) => {
						return (
							<li key={post.slug} className="m-result__item">
								<a href={`/${post.category.slug}/${post.slug}/`}>
									<div className="m-result__title">
										{post.title}
									</div>
									<div className="m-result__person">
										by {post.category.name}
									</div>
								</a>
							</li>
						)
					})}
				</ul>
			</div>
		</div>
	)
}

export default SearchResult