import { graphql, Link } from "gatsby"
import React from "react"

import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuoteLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons"

import "../../node_modules/slick-carousel/slick/slick.css"
import "../../node_modules/slick-carousel/slick/slick-theme.css"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Utils from "../components/utils"
import TopSlider from "../components/slider"
import ModalSearch from "../components/searchModal"

const Home = ({ data }) => {
  return (
    <Layout>
      <SEO />
      <main>
        <section className="l-top-feature top-feature">
          <div className="l-wrap top-feature__wrap">
            <h2 className="top-feature__hdg feature__hdg">
              <span className="top-feature__hdg--en">BLOG</span>
              <span className="top-feature__hdg--ja">
                名言アドバイザーブログ
              </span>
            </h2>
            <TopSlider />
            <h3 className="top-feature__link">
              <Link to="feature/">
                名言アドバイザーブログ
                <FontAwesomeIcon icon={faArrowRight} />
              </Link>
            </h3>
          </div>
        </section>

        <div className="l-top-recommend recommend">
          <div className="l-wrap">
            <h2 className="top-new__hdg">
              <span className="top-new__hdg--en">RECOMMEND</span>
              <span className="top-new__hdg--ja">おすすめ偉人</span>
            </h2>
            <ul className="recommend__list">
              <li className="recommend__item">
                {data.recommend1.edges.map(({ node }) => {
                  return (
                    <Link to={`person/${node.slug}`}>
                      <div className="recommend__unit" key={node.id}>
                        <div className="recommend__unit__details">
                          <div className="recommend__img">
                            <GatsbyImage
                              image={node.selfie.gatsbyImageData}
                              alt={node.selfie.title}
                            />
                          </div>
                          <h3 className="recommend__item__ttl">{node.name}</h3>
                        </div>
                      </div>
                    </Link>
                  )
                })}
              </li>

              <li className="recommend__item">
                {data.recommend2.edges.map(({ node }) => {
                  return (
                    <Link to={`person/${node.slug}`}>
                      <div className="recommend__unit" key={node.id}>
                        <div className="recommend__unit__details">
                          <div className="recommend__img">
                            <GatsbyImage
                              image={node.selfie.gatsbyImageData}
                              alt={node.selfie.title}
                            />
                          </div>
                          <h3 className="recommend__item__ttl">{node.name}</h3>
                        </div>
                      </div>
                    </Link>
                  )
                })}
              </li>

              <li className="recommend__item">
                {data.recommend3.edges.map(({ node }) => {
                  return (
                    <Link to={`person/${node.slug}`}>
                      <div className="recommend__unit" key={node.id}>
                        <div className="recommend__unit__details">
                          <div className="recommend__img">
                            <GatsbyImage
                              image={node.selfie.gatsbyImageData}
                              alt={node.selfie.title}
                            />
                          </div>
                          <h3 className="recommend__item__ttl">{node.name}</h3>
                        </div>
                      </div>
                    </Link>
                  )
                })}
              </li>

              <li className="recommend__item">
                {data.recommend4.edges.map(({ node }) => {
                  return (
                    <Link to={`person/${node.slug}`}>
                      <div className="recommend__unit" key={node.id}>
                        <div className="recommend__unit__details">
                          <div className="recommend__img">
                            <GatsbyImage
                              image={node.selfie.gatsbyImageData}
                              alt={node.selfie.title}
                            />
                          </div>
                          <h3 className="recommend__item__ttl">{node.name}</h3>
                        </div>
                      </div>
                    </Link>
                  )
                })}
              </li>

              <li className="recommend__item">
                {data.recommend5.edges.map(({ node }) => {
                  return (
                    <Link to={`person/${node.slug}`}>
                      <div className="recommend__unit" key={node.id}>
                        <div className="recommend__unit__details">
                          <div className="recommend__img">
                            <GatsbyImage
                              image={node.selfie.gatsbyImageData}
                              alt={node.selfie.title}
                            />
                          </div>
                          <h3 className="recommend__item__ttl">{node.name}</h3>
                        </div>
                      </div>
                    </Link>
                  )
                })}
              </li>

              <li className="recommend__item">
                {data.recommend6.edges.map(({ node }) => {
                  return (
                    <Link to={`person/${node.slug}`}>
                      <div className="recommend__unit" key={node.id}>
                        <div className="recommend__unit__details">
                          <div className="recommend__img">
                            <GatsbyImage
                              image={node.selfie.gatsbyImageData}
                              alt={node.selfie.title}
                            />
                          </div>
                          <h3 className="recommend__item__ttl">{node.name}</h3>
                        </div>
                      </div>
                    </Link>
                  )
                })}
              </li>

              <li className="recommend__item">
                {data.recommend7.edges.map(({ node }) => {
                  return (
                    <Link to={`person/${node.slug}`}>
                      <div className="recommend__unit" key={node.id}>
                        <div className="recommend__unit__details">
                          <div className="recommend__img">
                            <GatsbyImage
                              image={node.selfie.gatsbyImageData}
                              alt={node.selfie.title}
                            />
                          </div>
                          <h3 className="recommend__item__ttl">{node.name}</h3>
                        </div>
                      </div>
                    </Link>
                  )
                })}
              </li>
              <li className="recommend__item">
                {data.recommend8.edges.map(({ node }) => {
                  return (
                    <Link to={`person/${node.slug}`}>
                      <div className="recommend__unit" key={node.id}>
                        <div className="recommend__unit__details">
                          <div className="recommend__img">
                            <GatsbyImage
                              image={node.selfie.gatsbyImageData}
                              alt={node.selfie.title}
                            />
                          </div>
                          <h3 className="recommend__item__ttl">{node.name}</h3>
                        </div>
                      </div>
                    </Link>
                  )
                })}
              </li>

              <li className="recommend__item">
                {data.recommend9.edges.map(({ node }) => {
                  return (
                    <Link to={`person/${node.slug}`}>
                      <div className="recommend__unit" key={node.id}>
                        <div className="recommend__unit__details">
                          <div className="recommend__img">
                            <GatsbyImage
                              image={node.selfie.gatsbyImageData}
                              alt={node.selfie.title}
                            />
                          </div>
                          <h3 className="recommend__item__ttl">{node.name}</h3>
                        </div>
                      </div>
                    </Link>
                  )
                })}
              </li>

              <li className="recommend__item">
                {data.recommend10.edges.map(({ node }) => {
                  return (
                    <Link to={`person/${node.slug}`}>
                      <div className="recommend__unit" key={node.id}>
                        <div className="recommend__unit__details">
                          <div className="recommend__img">
                            <GatsbyImage
                              image={node.selfie.gatsbyImageData}
                              alt={node.selfie.title}
                            />
                          </div>
                          <h3 className="recommend__item__ttl">{node.name}</h3>
                        </div>
                      </div>
                    </Link>
                  )
                })}
              </li>

              <li className="recommend__item">
                {data.recommend11.edges.map(({ node }) => {
                  return (
                    <Link to={`person/${node.slug}`}>
                      <div className="recommend__unit" key={node.id}>
                        <div className="recommend__unit__details">
                          <div className="recommend__img">
                            <GatsbyImage
                              image={node.selfie.gatsbyImageData}
                              alt={node.selfie.title}
                            />
                          </div>
                          <h3 className="recommend__item__ttl">{node.name}</h3>
                        </div>
                      </div>
                    </Link>
                  )
                })}
              </li>

              <li className="recommend__item">
                {data.recommend12.edges.map(({ node }) => {
                  return (
                    <Link to={`person/${node.slug}`}>
                      <div className="recommend__unit" key={node.id}>
                        <div className="recommend__unit__details">
                          <div className="recommend__img">
                            <GatsbyImage
                              image={node.selfie.gatsbyImageData}
                              alt={node.selfie.title}
                            />
                          </div>
                          <h3 className="recommend__item__ttl">{node.name}</h3>
                        </div>
                      </div>
                    </Link>
                  )
                })}
              </li>
            </ul>
          </div>
        </div>

        <div className="l-top-tag tag">
          <div className="l-wrap">
            <h2 className="top-new__hdg">
              <span className="top-new__hdg--en">FOR YOU</span>
              <span className="top-new__hdg--ja">あなたへの名言</span>
            </h2>

            <ul className="tag__list">
              <li className="tag__item">
                {data.tag1.edges.map(({ node }) => {
                  return (
                    <Link to={`/tags/${node.slug}`}>
                      <div className="tag__unit" key={node.id}>
                        <div className="tag__unit__details">
                          <h3 className="tag__item__ttl">{node.title}</h3>
                        </div>
                      </div>
                    </Link>
                  )
                })}
              </li>

              <li className="tag__item">
                {data.tag2.edges.map(({ node }) => {
                  return (
                    <Link to={`/tags/${node.slug}`}>
                      <div className="tag__unit" key={node.id}>
                        <div className="tag__unit__details">
                          <h3 className="tag__item__ttl">{node.title}</h3>
                        </div>
                      </div>
                    </Link>
                  )
                })}
              </li>

              <li className="tag__item">
                {data.tag3.edges.map(({ node }) => {
                  return (
                    <Link to={`/tags/${node.slug}`}>
                      <div className="tag__unit" key={node.id}>
                        <div className="tag__unit__details">
                          <h3 className="tag__item__ttl">{node.title}</h3>
                        </div>
                      </div>
                    </Link>
                  )
                })}
              </li>

              <li className="tag__item">
                {data.tag4.edges.map(({ node }) => {
                  return (
                    <Link to={`/tags/${node.slug}`}>
                      <div className="tag__unit" key={node.id}>
                        <div className="tag__unit__details">
                          <h3 className="tag__item__ttl">{node.title}</h3>
                        </div>
                      </div>
                    </Link>
                  )
                })}
              </li>

              <li className="tag__item">
                {data.tag5.edges.map(({ node }) => {
                  return (
                    <Link to={`/tags/${node.slug}`}>
                      <div className="tag__unit" key={node.id}>
                        <div className="tag__unit__details">
                          <h3 className="tag__item__ttl">{node.title}</h3>
                        </div>
                      </div>
                    </Link>
                  )
                })}
              </li>

              <li className="tag__item">
                {data.tag6.edges.map(({ node }) => {
                  return (
                    <Link to={`/tags/${node.slug}`}>
                      <div className="tag__unit" key={node.id}>
                        <div className="tag__unit__details">
                          <h3 className="tag__item__ttl">{node.title}</h3>
                        </div>
                      </div>
                    </Link>
                  )
                })}
              </li>

              <li className="tag__item">
                {data.tag7.edges.map(({ node }) => {
                  return (
                    <Link to={`/tags/${node.slug}`}>
                      <div className="tag__unit" key={node.id}>
                        <div className="tag__unit__details">
                          <h3 className="tag__item__ttl">{node.title}</h3>
                        </div>
                      </div>
                    </Link>
                  )
                })}
              </li>

              <li className="tag__item">
                {data.tag8.edges.map(({ node }) => {
                  return (
                    <Link to={`/tags/${node.slug}`}>
                      <div className="tag__unit" key={node.id}>
                        <div className="tag__unit__details">
                          <h3 className="tag__item__ttl">{node.title}</h3>
                        </div>
                      </div>
                    </Link>
                  )
                })}
              </li>

              <li className="tag__item">
                {data.tag9.edges.map(({ node }) => {
                  return (
                    <Link to={`/tags/${node.slug}`}>
                      <div className="tag__unit" key={node.id}>
                        <div className="tag__unit__details">
                          <h3 className="tag__item__ttl">{node.title}</h3>
                        </div>
                      </div>
                    </Link>
                  )
                })}
              </li>

              <li className="tag__item">
                {data.tag10.edges.map(({ node }) => {
                  return (
                    <Link to={`/tags/${node.slug}`}>
                      <div className="tag__unit" key={node.id}>
                        <div className="tag__unit__details">
                          <h3 className="tag__item__ttl">{node.title}</h3>
                        </div>
                      </div>
                    </Link>
                  )
                })}
              </li>

              <li className="tag__item">
                {data.tag11.edges.map(({ node }) => {
                  return (
                    <Link to={`/tags/${node.slug}`}>
                      <div className="tag__unit" key={node.id}>
                        <div className="tag__unit__details">
                          <h3 className="tag__item__ttl">{node.title}</h3>
                        </div>
                      </div>
                    </Link>
                  )
                })}
              </li>

              <li className="tag__item">
                {data.tag12.edges.map(({ node }) => {
                  return (
                    <Link to={`/tags/${node.slug}`}>
                      <div className="tag__unit" key={node.id}>
                        <div className="tag__unit__details">
                          <h3 className="tag__item__ttl">{node.title}</h3>
                        </div>
                      </div>
                    </Link>
                  )
                })}
              </li>

              <li className="tag__item">
                {data.tag13.edges.map(({ node }) => {
                  return (
                    <Link to={`/tags/${node.slug}`}>
                      <div className="tag__unit" key={node.id}>
                        <div className="tag__unit__details">
                          <h3 className="tag__item__ttl">{node.title}</h3>
                        </div>
                      </div>
                    </Link>
                  )
                })}
              </li>

              <li className="tag__item">
                {data.tag14.edges.map(({ node }) => {
                  return (
                    <Link to={`/tags/${node.slug}`}>
                      <div className="tag__unit" key={node.id}>
                        <div className="tag__unit__details">
                          <h3 className="tag__item__ttl">{node.title}</h3>
                        </div>
                      </div>
                    </Link>
                  )
                })}
              </li>

              <li className="tag__item">
                {data.tag15.edges.map(({ node }) => {
                  return (
                    <Link to={`/tags/${node.slug}`}>
                      <div className="tag__unit" key={node.id}>
                        <div className="tag__unit__details">
                          <h3 className="tag__item__ttl">{node.title}</h3>
                        </div>
                      </div>
                    </Link>
                  )
                })}
              </li>

              <li className="tag__item">
                {data.tag16.edges.map(({ node }) => {
                  return (
                    <Link to={`/tags/${node.slug}`}>
                      <div className="tag__unit" key={node.id}>
                        <div className="tag__unit__details">
                          <h3 className="tag__item__ttl">{node.title}</h3>
                        </div>
                      </div>
                    </Link>
                  )
                })}
              </li>

              <li className="tag__item">
                {data.tag17.edges.map(({ node }) => {
                  return (
                    <Link to={`/tags/${node.slug}`}>
                      <div className="tag__unit" key={node.id}>
                        <div className="tag__unit__details">
                          <h3 className="tag__item__ttl">{node.title}</h3>
                        </div>
                      </div>
                    </Link>
                  )
                })}
              </li>

              <li className="tag__item">
                {data.tag18.edges.map(({ node }) => {
                  return (
                    <Link to={`/tags/${node.slug}`}>
                      <div className="tag__unit" key={node.id}>
                        <div className="tag__unit__details">
                          <h3 className="tag__item__ttl">{node.title}</h3>
                        </div>
                      </div>
                    </Link>
                  )
                })}
              </li>

              <li className="tag__item">
                {data.tag19.edges.map(({ node }) => {
                  return (
                    <Link to={`/tags/${node.slug}`}>
                      <div className="tag__unit" key={node.id}>
                        <div className="tag__unit__details">
                          <h3 className="tag__item__ttl">{node.title}</h3>
                        </div>
                      </div>
                    </Link>
                  )
                })}
              </li>

              <li className="tag__item">
                {data.tag20.edges.map(({ node }) => {
                  return (
                    <Link to={`/tags/${node.slug}`}>
                      <div className="tag__unit" key={node.id}>
                        <div className="tag__unit__details">
                          <h3 className="tag__item__ttl">{node.title}</h3>
                        </div>
                      </div>
                    </Link>
                  )
                })}
              </li>

              <li className="tag__item">
                {data.tag21.edges.map(({ node }) => {
                  return (
                    <Link to={`/tags/${node.slug}`}>
                      <div className="tag__unit" key={node.id}>
                        <div className="tag__unit__details">
                          <h3 className="tag__item__ttl">{node.title}</h3>
                        </div>
                      </div>
                    </Link>
                  )
                })}
              </li>

              <li className="tag__item">
                {data.tag22.edges.map(({ node }) => {
                  return (
                    <Link to={`/tags/${node.slug}`}>
                      <div className="tag__unit" key={node.id}>
                        <div className="tag__unit__details">
                          <h3 className="tag__item__ttl">{node.title}</h3>
                        </div>
                      </div>
                    </Link>
                  )
                })}
              </li>

              <li className="tag__item">
                {data.tag23.edges.map(({ node }) => {
                  return (
                    <Link to={`/tags/${node.slug}`}>
                      <div className="tag__unit" key={node.id}>
                        <div className="tag__unit__details">
                          <h3 className="tag__item__ttl">{node.title}</h3>
                        </div>
                      </div>
                    </Link>
                  )
                })}
              </li>

              <li className="tag__item">
                {data.tag24.edges.map(({ node }) => {
                  return (
                    <Link to={`/tags/${node.slug}`}>
                      <div className="tag__unit" key={node.id}>
                        <div className="tag__unit__details">
                          <h3 className="tag__item__ttl">{node.title}</h3>
                        </div>
                      </div>
                    </Link>
                  )
                })}
              </li>

              <li className="tag__item">
                {data.tag25.edges.map(({ node }) => {
                  return (
                    <Link to={`/tags/${node.slug}`}>
                      <div className="tag__unit" key={node.id}>
                        <div className="tag__unit__details">
                          <h3 className="tag__item__ttl">{node.title}</h3>
                        </div>
                      </div>
                    </Link>
                  )
                })}
              </li>

              <li className="tag__item">
                {data.tag26.edges.map(({ node }) => {
                  return (
                    <Link to={`/tags/${node.slug}`}>
                      <div className="tag__unit" key={node.id}>
                        <div className="tag__unit__details">
                          <h3 className="tag__item__ttl">{node.title}</h3>
                        </div>
                      </div>
                    </Link>
                  )
                })}
              </li>
            </ul>
          </div>
        </div>

        <div className="l-top-search">
          <div className="l-wrap">
            {/* <h2 className="m-heading m-heading--center m-heading--no-mb m-heading--white">キーワードから名言を探す</h2> */}
            <ModalSearch />
          </div>
          <StaticImage
            src="../assets/images/library_bg01.jpg"
            alt="キーワードから名言を探す"
            className="l-top-search__bg"
          />
        </div>

        <div className="l-top-pc">
          <section className="l-top-new top-new">
            <div className="l-wrap">
              <h2 className="top-new__hdg">
                <span className="top-new__hdg--en">NEW</span>
                <span className="top-new__hdg--ja">新着名言</span>
              </h2>

              <div className="m-quotes">
                {data.posts.edges.map(({ node }) => {
                  const num = Math.floor(Math.random() * Utils.color.length)
                  return (
                    <article
                      className={`m-quotes__item ${Utils.color[num]}`}
                      key={node.id}
                    >
                      <Link to={`/${node.category.slug}/${node.slug}/`}>
                        <h3 className="m-quotes__hdg">
                          <FontAwesomeIcon icon={faQuoteLeft} />
                          <span className="m-quotes__txt">{node.title}</span>
                        </h3>
                      </Link>

                      <dl className="m-quotes__details">
                        <dt className="m-quotes__img">
                          <Link to={`/person/${node.category.slug}/`}>
                            <figure>
                              <GatsbyImage
                                image={node.category.selfie.gatsbyImageData}
                                alt={node.category.selfie.title}
                              />
                            </figure>
                          </Link>
                        </dt>
                        <dd className="m-quotes__name">
                          <Link to={`/person/${node.category.slug}/`}>
                            {node.category.name}
                          </Link>
                        </dd>
                      </dl>
                    </article>
                  )
                })}
              </div>
            </div>
          </section>

          <div className="l-top-pc--side">
            <section className="l-top-situation top-situation">
              <div className="l-wrap">
                <h2 className="top-situation__hdg">
                  <span className="top-situation__hdg--en">type</span>
                  <span className="top-situation__hdg--ja">タイプから探す</span>
                </h2>
                <ul className="top-situation__list">
                  <li className="top-situation__item">
                    {data.type1.edges.map(({ node }) => (
                      <Link to={`/tags/${node.slug}`} key={node.id}>
                        <h3 className="top-situation__ttl">{node.title}</h3>
                      </Link>
                    ))}
                  </li>

                  <li className="top-situation__item">
                    {data.type2.edges.map(({ node }) => (
                      <Link to={`/tags/${node.slug}`} key={node.id}>
                        <h3 className="top-situation__ttl">{node.title}</h3>
                      </Link>
                    ))}
                  </li>

                  <li className="top-situation__item">
                    {data.type3.edges.map(({ node }) => (
                      <Link to={`/tags/${node.slug}`} key={node.id}>
                        <h3 className="top-situation__ttl">{node.title}</h3>
                      </Link>
                    ))}
                  </li>

                  <li className="top-situation__item">
                    {data.type4.edges.map(({ node }) => (
                      <Link to={`/tags/${node.slug}`} key={node.id}>
                        <h3 className="top-situation__ttl">{node.title}</h3>
                      </Link>
                    ))}
                  </li>

                  <li className="top-situation__item">
                    {data.type5.edges.map(({ node }) => (
                      <Link to={`/tags/${node.slug}`} key={node.id}>
                        <h3 className="top-situation__ttl">{node.title}</h3>
                      </Link>
                    ))}
                  </li>

                  <li className="top-situation__item">
                    {data.type6.edges.map(({ node }) => (
                      <Link to={`/tags/${node.slug}`} key={node.id}>
                        <h3 className="top-situation__ttl">{node.title}</h3>
                      </Link>
                    ))}
                  </li>

                  <li className="top-situation__item">
                    {data.type7.edges.map(({ node }) => (
                      <Link to={`/tags/${node.slug}`} key={node.id}>
                        <h3 className="top-situation__ttl">{node.title}</h3>
                      </Link>
                    ))}
                  </li>

                  <li className="top-situation__item">
                    {data.type8.edges.map(({ node }) => (
                      <Link to={`/tags/${node.slug}`} key={node.id}>
                        <h3 className="top-situation__ttl">{node.title}</h3>
                      </Link>
                    ))}
                  </li>

                  <li className="top-situation__item">
                    {data.type9.edges.map(({ node }) => (
                      <Link to={`/tags/${node.slug}`} key={node.id}>
                        <h3 className="top-situation__ttl">{node.title}</h3>
                      </Link>
                    ))}
                  </li>

                  <li className="top-situation__item">
                    {data.type10.edges.map(({ node }) => (
                      <Link to={`/tags/${node.slug}`} key={node.id}>
                        <h3 className="top-situation__ttl">{node.title}</h3>
                      </Link>
                    ))}
                  </li>

                  <li className="top-situation__item">
                    {data.type11.edges.map(({ node }) => (
                      <Link to={`/tags/${node.slug}`} key={node.id}>
                        <h3 className="top-situation__ttl">{node.title}</h3>
                      </Link>
                    ))}
                  </li>

                  <li className="top-situation__item">
                    {data.type12.edges.map(({ node }) => (
                      <Link to={`/tags/${node.slug}`} key={node.id}>
                        <h3 className="top-situation__ttl">{node.title}</h3>
                      </Link>
                    ))}
                  </li>
                </ul>
              </div>
            </section>

            <section className="l-top-person top-person">
              <div className="l-wrap">
                <h2 className="top-person__hdg">
                  <span className="top-person__hdg--en">Person</span>
                  <span className="top-person__hdg--ja">偉人から探す</span>
                </h2>
                <ul className="top-person__list">
                  {data.cate.edges.map(({ node }) => (
                    <li className="top-person__item" key={node.id}>
                      <Link to={`person/${node.slug}`}>
                        <dl className="top-person__details">
                          <dd className="top-person__img">
                            <GatsbyImage
                              className="top-person__photo"
                              image={node.selfie.gatsbyImageData}
                              alt={node.selfie.title}
                            />
                          </dd>
                          <dt className="top-person__ttl">{node.name}</dt>
                        </dl>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </section>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query {
    posts: allContentfulBlogPost(
      sort: { order: DESC, fields: publishDate }
      skip: 0
      limit: 10
    ) {
      edges {
        node {
          title
          id
          slug
          category {
            slug
            name
            selfie {
              gatsbyImageData(layout: FULL_WIDTH)
              description
              title
            }
          }
          eyecatch {
            gatsbyImageData(layout: FULL_WIDTH)
            description
          }
        }
      }
    }
    cate: allContentfulCategory(
      sort: { fields: sort, order: DESC }
      limit: 30
    ) {
      edges {
        node {
          id
          slug
          name
          selfie {
            gatsbyImageData(layout: FULL_WIDTH)
            title
            description
          }
        }
      }
    }

    tags: allContentfulTags {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    recommend1: allContentfulCategory(
      filter: { slug: { eq: "leonardo-da-vinci" } }
    ) {
      edges {
        node {
          name
          id
          slug
          selfie {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }

    recommend2: allContentfulCategory(
      filter: { slug: { eq: "abraham-lincoln" } }
    ) {
      edges {
        node {
          name
          id
          slug
          selfie {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }

    recommend3: allContentfulCategory(
      filter: { slug: { eq: "albert-einstein" } }
    ) {
      edges {
        node {
          name
          id
          slug
          selfie {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }

    recommend4: allContentfulCategory(
      filter: { slug: { eq: "dalai-lama-14th" } }
    ) {
      edges {
        node {
          name
          id
          slug
          selfie {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }

    recommend5: allContentfulCategory(filter: { slug: { eq: "bob-marley" } }) {
      edges {
        node {
          name
          id
          slug
          selfie {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }

    recommend6: allContentfulCategory(filter: { slug: { eq: "ichiro" } }) {
      edges {
        node {
          name
          id
          slug
          selfie {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }

    recommend7: allContentfulCategory(
      filter: { slug: { eq: "jeffrey-bezos" } }
    ) {
      edges {
        node {
          name
          id
          slug
          selfie {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }

    recommend8: allContentfulCategory(filter: { slug: { eq: "sakyamuni" } }) {
      edges {
        node {
          name
          id
          slug
          selfie {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }

    recommend9: allContentfulCategory(filter: { slug: { eq: "vivekananda" } }) {
      edges {
        node {
          name
          id
          slug
          selfie {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }

    recommend10: allContentfulCategory(
      filter: { slug: { eq: "soichiro-honda" } }
    ) {
      edges {
        node {
          name
          id
          slug
          selfie {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }

    recommend11: allContentfulCategory(
      filter: { slug: { eq: "mutta-namba" } }
    ) {
      edges {
        node {
          name
          id
          slug
          selfie {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }

    recommend12: allContentfulCategory(
      filter: { slug: { eq: "lionel-messi" } }
    ) {
      edges {
        node {
          name
          id
          slug
          selfie {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }

    tag1: allContentfulTags(
      filter: { title: { eq: "クリエイター・アーティストへの名言" } }
    ) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    tag2: allContentfulTags(
      filter: { title: { eq: "愛について説かれた名言" } }
    ) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    tag3: allContentfulTags(filter: { title: { eq: "仏教" } }) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    tag4: allContentfulTags(
      filter: { title: { eq: "チャレンジする勇気をくれる名言" } }
    ) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    tag5: allContentfulTags(filter: { title: { eq: "健康についての名言" } }) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    tag6: allContentfulTags(
      filter: { title: { eq: "社会人のあなたが読むべき名言" } }
    ) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    tag7: allContentfulTags(
      filter: { title: { eq: "友人・友情についての名言" } }
    ) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    tag8: allContentfulTags(
      filter: { title: { eq: "働くことについての名言" } }
    ) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    tag9: allContentfulTags(
      filter: { title: { eq: "仕事のコツについての名言" } }
    ) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    tag10: allContentfulTags(
      filter: { title: { eq: "本について説かれた名言" } }
    ) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    tag11: allContentfulTags(
      filter: { title: { eq: "勉強・学習についての名言" } }
    ) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    tag12: allContentfulTags(
      filter: { title: { eq: "幸せ・幸福についての名言" } }
    ) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    tag13: allContentfulTags(filter: { title: { eq: "習慣についての名言" } }) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    tag14: allContentfulTags(filter: { title: { eq: "学生への名言" } }) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    tag15: allContentfulTags(
      filter: { title: { eq: "上司・指導者への名言" } }
    ) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    tag16: allContentfulTags(filter: { title: { eq: "智慧の名言" } }) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    tag17: allContentfulTags(
      filter: { title: { eq: "成功哲学についての名言" } }
    ) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    tag18: allContentfulTags(filter: { title: { eq: "主婦に向けた名言" } }) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    tag19: allContentfulTags(
      filter: { title: { eq: "自分は自分のままでいい" } }
    ) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    tag20: allContentfulTags(
      filter: { title: { eq: "定年後のあなたへの名言" } }
    ) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    tag21: allContentfulTags(
      filter: { title: { eq: "人生哲学についての名言" } }
    ) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    tag22: allContentfulTags(
      filter: { title: { eq: "落ち込んだ人への名言" } }
    ) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    tag23: allContentfulTags(
      filter: { title: { eq: "金・財産についての名言" } }
    ) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    tag24: allContentfulTags(
      filter: { title: { eq: "教育・指導についての名言" } }
    ) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    tag25: allContentfulTags(filter: { title: { eq: "心を癒やす名言" } }) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    tag26: allContentfulTags(
      filter: { title: { eq: "ユーモアについての名言" } }
    ) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    type1: allContentfulTags(filter: { title: { eq: "実業家" } }) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    type2: allContentfulTags(filter: { title: { eq: "マンガ" } }) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    type3: allContentfulTags(filter: { title: { eq: "スポーツ選手" } }) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    type4: allContentfulTags(filter: { title: { eq: "学者" } }) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    type5: allContentfulTags(filter: { title: { eq: "政治家" } }) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    type6: allContentfulTags(filter: { title: { eq: "アーティスト" } }) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    type7: allContentfulTags(filter: { title: { eq: "戦士" } }) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    type8: allContentfulTags(filter: { title: { eq: "教育者" } }) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    type9: allContentfulTags(filter: { title: { eq: "小説家" } }) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    type10: allContentfulTags(filter: { title: { eq: "思想家" } }) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    type11: allContentfulTags(filter: { title: { eq: "改革者" } }) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    type12: allContentfulTags(filter: { title: { eq: "宗教家" } }) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }
  }
`

export default Home
