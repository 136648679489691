import React from 'react';
import Modal from 'react-modal';
import Search from "./search";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSearch
} from '@fortawesome/free-solid-svg-icons'

Modal.setAppElement('#___gatsby')  //public/htmlのid参照
class ModalWindow extends React.Component {
  constructor() {
    super();
    this.state = {
      modalIsOpen: false
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }
  openModal() {
    this.setState({modalIsOpen: true});
  }
  closeModal() {
    this.setState({modalIsOpen: false});
  }
  render() {
    return (
      <div className="l-modal-wrapper modal">
        <h2 className="modal__hdg">
          <span className="modal__hdg__inner">キーワードから名言を探す</span>
        </h2>
        <button onClick={this.openModal} className="modal__btn">
          <span className="modal__icon">
            <FontAwesomeIcon icon={faSearch} />
          </span>
        </button>
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          contentLabel="Search Modal"
          className="l-modal-search-window"
          overlayClassName="l-modal-search-overlay"
        >
          <Search />
          <button onClick={this.closeModal} className="modal__btn modal__btn--close">
            閉じる
          </button>
        </Modal>
      </div>
    );
  }
}
export default ModalWindow;